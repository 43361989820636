<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-17 11:10:50
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-01-17 15:32:05
 * @FilePath: \yda_web_manage\src\views\seal\applyList\components\baseApplyList.vue
 * @Description: 用印记录-常规用印列表3.0
-->
<template>
  <template v-if="pageLoading">
    <div class="base-apply-list">
      <a-spin size="large" class="loading-animation" />
    </div>
  </template>
  <template v-else>
    <section>
      <section class="form-style">
        <a-form layout="inline" ref="formRef" :model="state">
          <!-- 搜索 -->
          <a-form-item label="" class="form-center" name="search">
            <a-input-group compact>
              <a-select v-model:value="state.printType" style="width: 130px">
                <a-select-option
                  v-for="(value, key) in state.searchTypeOptions"
                  :key="key"
                  :value="value.code"
                  >{{ value.desc }}</a-select-option
                >
              </a-select>

              <a-input
                style="width: 227px; height: 32px"
                v-model:value.trim="state.search"
                :maxlength="30"
                placeholder="请输入"
                allowClear
              />
            </a-input-group>
          </a-form-item>
          <!-- 业务类型 -->
          <a-form-item label="业务类型：" name="processType">
            <a-select
              v-model:value="state.processType"
              allowClear
              class="form-select"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(value, key) in processTypeObj"
                :key="key"
                :value="key"
              >
                {{ value.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- 流程名称 -->
          <a-form-item label="流程名称：" name="businessProcess">
            <a-input
              style="width: 146px; height: 32px"
              v-model:value.trim="state.businessProcess"
              :maxlength="30"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <!-- TODO 等待UI图和联调 -->
          <a-form-item label="用印模式：" name="selectSealType">
            <!-- <a-select ref="select" v-model:value="state.selectSealType" style="width: 120px" placeholder="请选择">
              <a-select-option value="jack">常规盖印</a-select-option>
              <a-select-option value="lucy">连续盖印</a-select-option>
              <a-select-option value="disabled" disabled>远程盖印</a-select-option>
            </a-select> -->
            <a-select
              v-model:value="state.selectSealType"
              mode="tags"
              style="min-width: 220px; height: 32px;"
              placeholder="请选择"
              :options="[
                { value: '常规盖印' },
                { value: '连续盖印' },
                { value: '远程盖印' }
              ]"
              @change="handleChange"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="" class="form-center" name="searchdeptVal">
            <a-input-group compact>
              <a-select v-model:value="state.deptType" style="width: 130px">
                <a-select-option
                  v-for="(value, key) in printDeptOptions"
                  :key="key"
                  :value="value.code"
                  >{{ value.desc }}</a-select-option
                >
              </a-select>

              <!-- <a-input
                style="width: 227px; height: 32px"
                v-model:value.trim="state.searchdeptVal"
                :maxlength="30"
                placeholder="请输入"
                allowClear
              /> -->
              <DeptSelect v-model:deptList="deptList" style="width: 225px;" />
            </a-input-group>
          </a-form-item>
          <!-- 盖印时间 -->
          <a-form-item label="盖印时间：" name="submitTime">
            <a-range-picker
              :disabledDate="disabledDate"
              separator="→"
              allowClear
              v-model:value="state.submitTime"
            >
              <template #suffixIcon>
                <CalendarOutlined />
              </template>
            </a-range-picker>
          </a-form-item>
          <!-- 重置/查询 -->
          <a-form-item class="form-btns">
            <a-button @click="reset">重置</a-button>
            <a-button class="form-btn" @click="searchList">查询</a-button>
          </a-form-item>
        </a-form>
      </section>
      <!-- 表格数据 -->
      <section class="table-style">
        <div class="table-head">
          <span class="title">用印列表</span>
          <!-- 导出 -->
          <div class="btn-block">
            <a-button
              class="add-btn"
              @click="exportTable"
              v-btn="'PC_SEAL_LOG_EXPORT'"
              ><i class="iconfont icon-daochu head-export" />导出</a-button
            >
          </div>
        </div>
        <a-table
          :columns="companyColumns"
          :dataSource="state.dataSource"
          :pagination="state.pagination"
          @change="handleTableChange"
          :loading="state.tableLoading"
          :rowKey="(r) => `${r.documentId}-${r.sealId}`"
          ref="tableRef"
          :scroll="{ x: 400 }"
        >
          <!-- 序号 -->
          <template #id="{ index }">{{
            (state.pagination.index - 1) * state.pagination.pageSize + index + 1
          }}</template>
          <!--部门 -->
          <template #departmentName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 流程名称 -->
          <template #processName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 流程主题 -->
          <template #fileName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 申请人 -->
          <template #staffName="{ record }">
            <div class="text-box">
              <a-tooltip
                placement="bottomLeft"
                v-if="
                  record.staffName.length > 4 ||
                    record.departmentName.length > 6
                "
              >
                <template #title>
                  <span>{{
                    record.staffName + '-' + record.departmentName
                  }}</span>
                </template>
                {{ applyFilter(record) ?? '-' }}
              </a-tooltip>
              <span v-else>{{ applyFilter(record) ?? '-' }}</span>
            </div>
          </template>
          <!-- 盖印人 -->
          <template #sealUser="{ record }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="sealUserLength(record)">
                <template #title>
                  <span
                    v-for="(item, key) in sealUserFilter(record, 2)"
                    :key="key"
                    >{{ item }}<br
                  /></span>
                </template>
                {{ record.sealUserOne ?? '-' }}
              </a-tooltip>
              <span v-else>{{ record.sealUserOne ?? '-' }}</span>
            </div>
          </template>
          <!-- 印章名称 -->
          <template #sealNames="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 盖印时间 -->
          <template #sealTime="{ text }">
            <span>{{ text ?? '-' }}</span>
          </template>
          <!-- 用印状态 -->
          <template #sealStatus="{ record, text }">
            <span
              class="dottos"
              :style="{ background: sealStatus[text]?.color }"
              v-if="record.sealType !== 2"
            ></span>
            <span>{{
              record.sealType !== 2 ? sealStatus[text]?.status : '-'
            }}</span>
          </template>
          <!-- 操作 -->
          <template #action="{ record }">
            <a
              @click="
                $router.push(
                  `/seal/applyList/detail?documentId=${record.documentId}&processInstanceId=${record.processInstanceId}`
                )
              "
              class="table-text-color"
              >查看</a
            >
          </template>
        </a-table>
      </section>
    </section>
  </template>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { companyColumns } from '../columns'
import { getApplyList, exportBaseList } from '@/apis/seal'
import DeptSelect from '../../../businessManage/staff/components/staffModal/deptSelect.vue'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import moment from 'moment'
import { CalendarOutlined } from '@ant-design/icons-vue'
import {
  examineStatusObj,
  sealStatusObj,
  paginationParams,
  searchType,
  sealStatus,
  processTypeObj
} from '@/utils/constData'
import { getEnums } from '@/apis/user'
import { useStore } from 'vuex'
const printDeptOptions = [
  { code: 2, desc: '申请人部门' },
  { code: 1, desc: '盖印人部门' }
]
export default defineComponent({
  name: 'base-apply-list',
  props: {
    userId: {
      type: Number,
      default: 1
    },
    reload: {
      type: Boolean,
      default: false
    },
    status: {
      default: undefined
    }
  },
  components: {
    CalendarOutlined,
    DeptSelect
  },
  setup(props) {
    const pageLoading = ref(true)
    const formRef = ref()
    const tableRef = ref()
    const state = reactive({
      search: undefined, // 盖印人搜索
      searchdeptVal: undefined, //用印人部门
      printType: 1, //盖印人类型
      deptType: 2, //部门类型
      businessProcess: undefined, //企业版流程名称
      processType: undefined, //业务类型
      selectSealType: [], // 用印模式
      submitTime: [],
      sealStatus: props.status, //用印状态
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      tableLoading: true,
      timeSort: undefined,
      currentNum: 1,
      searchTypeOptions: [] //搜索类型选项
    })

    const store = useStore()

    const applyFilter = (record, type) => {
      let val, n, d
      if (record.departmentName) {
        record.staffName.length > 4
          ? (n = `${record.staffName.slice(0, 4)}...`)
          : (n = record.staffName)
        record.departmentName.length > 6
          ? (d = `${record.departmentName.slice(0, 6)}...`)
          : (d = record.departmentName)
        val = n + '-' + d
      } else {
        val = record.staffName
      }
      return val
    }
    const sealUserFilter = (record, type) => {
      let val,
        listRecord = record.documentSealStaffRelDTOS
      if (listRecord.length && type === 2) {
        val = []
        listRecord.forEach((item) => {
          val.push(
            `${item.affixedStaffname}-${item.affixedDepartmentName || ''}`
          )
        })
        return val
      }
    }
    const sealUserLength = (record) => {
      if (record) {
        let list = record.sealUserOne.split('-')
        return (
          list[0].length > 4 ||
          list[1]?.length > 6 ||
          record.documentSealStaffRelDTOS.length > 1
        )
      }
    }
    const deptList = ref([])
    //获取表单
    const getList = async () => {
      state.tableLoading = true

      let sizerTypeList = ''
      state.selectSealType?.map((it) => {
        if (it == '常规盖印') {
          sizerTypeList += ',2'
        } else if (it == '连续盖印') {
          sizerTypeList += ',4'
        } else if (it == '远程盖印') {
          sizerTypeList += ',3'
        }
      })
      let departmentIdLists = []
      deptList.value.forEach((item) => {
        departmentIdLists.push(item.departmentId)
      })

      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        search: state.search, //模糊查询内容
        searchType: state.printType,
        timeSort: state.timeSort, //提交时间排序
        startTime: state.submitTime.length
          ? `${moment(state.submitTime[0]).format('YYYY-MM-DD')} 00:00:00`
          : '', //开始时间
        endTime: state.submitTime.length
          ? `${moment(state.submitTime[1]).format('YYYY-MM-DD')} 00:00:00`
          : '', //结束时间
        processNameSearch: state.businessProcess, //流程名称
        processType: state.processType, //业务类型
        sizerTypeList: sizerTypeList // 盖印模式
      }
      departmentIdLists.length
        ? ((params.departmentType = state.deptType),
          (params.departmentIdList = departmentIdLists.join(',')))
        : null
      console.log('selectSealType', params, deptList.value)
      const res = await getApplyList(params)
      state.dataSource = res.data
      const reg = new RegExp(state.search)
      state.dataSource?.map((item) => {
        const sealNameList = item.documentSealRelVOS.map((item) => {
          return item.sealName
        })
        const applySums = item.documentSealRelVOS.map((item) => {
          return item.applicationNumber
        })
        const sealSums = item.documentSealRelVOS.map((item) => {
          return item.practicalNumber
        })
        if (item.documentSealStaffRelDTOS.length) {
          let obj = {},
            n,
            d,
            c
          if (state.printType === 4) {
            obj = item.documentSealStaffRelDTOS.find((item) => {
              return reg.test(item.affixedStaffname)
            })
          } else {
            obj = item.documentSealStaffRelDTOS[0]
          }
          obj.affixedStaffname.length > 4
            ? (n = `${obj.affixedStaffname.slice(0, 4)}...`)
            : (n = obj.affixedStaffname)
          obj.affixedDepartmentName?.length > 6
            ? (d = `${obj.affixedDepartmentName.slice(0, 6)}...`)
            : (d = obj.affixedDepartmentName)
          d ? (item.sealUserOne = n + '-' + d) : (item.sealUserOne = n)
        } else {
          item.sealUserOne = '-'
        }
        item.sealNames = sealNameList.join(',')
        item.applySums =
          applySums.length >= 1
            ? applySums.reduce((preValue, curValue) => {
                return preValue + curValue
              })
            : 0
        item.practicalNumber =
          sealSums.length >= 1
            ? sealSums.reduce((preValue, curValue) => {
                return preValue + curValue
              })
            : 0
      })
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }

    // 用印类型变更
    const handleChange = (value) => {
      console.log(state.selectSealType)
    }

    const handleTableChange = ({ current, pageSize }, filters, { order }) => {
      console.log('order的值', order)
      // 排序
      if (order == 'ascend') {
        state.timeSort = 1
      } else if (order == 'descend') {
        state.timeSort = 0
      } else {
        state.timeSort = undefined
      }
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    const exportTable = () => {
      let sessionId = localStorage.getItem('yda-qywx-sessionId')
      if (sessionId == 'null' || !sessionId) {
        sessionId = undefined
      }
      let departmentIdLists = []
      deptList.value.forEach((item) => {
        departmentIdLists.push(item.departmentId)
      })
      const params = {
        search: state.search, //模糊查询内容
        timeSort: state.timeSort, //提交时间排序
        startTime: state.submitTime.length
          ? `${moment(state.submitTime[0]).format('YYYY-MM-DD')} 00:00:00`
          : '', //开始时间
        endTime: state.submitTime.length
          ? `${moment(state.submitTime[1]).format('YYYY-MM-DD')} 00:00:00`
          : '', //结束时间
        processNameSearch: state.businessProcess, //流程名称
        processType: state.processType, //业务类型
        searchType: state.printType, //搜索项类型
        sessionId
      }
      departmentIdLists.length
        ? ((params.departmentType = state.deptType),
          (params.departmentIdList = departmentIdLists.join(',')))
        : null
      window.location = exportBaseList(params)
      setTimeout(() => {
        cmsNotice('success', '正在为您导出，请耐心等待...')
      }, 200)
    }

    // 查询
    const searchList = () => {
      state.pagination.current = 1
      getList()
    }

    //重置筛选条件(3.4.0本地有缓存的条件功能，新增条件的记得加一下重置)
    const formRest = () => {
      state.deptType = 2
      state.printType = 1
      state.search = undefined
      state.searchdeptVal = undefined
      state.timeSort = undefined
      state.submitTime = []
      state.businessProcess = undefined
      state.processType = undefined
      state.selectSealType = []
      deptList.value = []
    }

    // 重置
    const reset = () => {
      formRef.value.resetFields()
      formRest()
      state.printType = 1
      state.deptType = 2
      deptList.value = []
      state.pagination.current = 1
      getList()
    }

    // 日期选择器：当天之后不可选
    const disabledDate = (current) => {
      return current && current > moment().endOf('day')
    }

    onMounted(async () => {
      //判断是否存在缓存参数
      const params = store.state.sealManage.sealRecordParams
      if (params) {
        mergeParams(params)
      }
      await getEnums({ kind: 'DOCUMENT_SEARCH_TYPE' }).then((res) => {
        if (res.success) {
          state.searchTypeOptions = res.data
        }
      })

      await getList()
      pageLoading.value = false
    })

    watch(
      () => props.reload,
      () => {
        getList()
      }
    )

    //监听筛选参数变化
    watch(
      () => state,
      () => {
        const params = {
          deptType: state.deptType,
          printType: state.printType,
          current: state.pagination.current,
          pageSize: state.pagination.pageSize,
          search: state.search, //模糊查询内容
          searchdeptVal: state.searchdeptVal,
          timeSort: state.timeSort, //提交时间排序
          submitTime: state.submitTime,
          businessProcess: state.businessProcess, //流程名称
          processType: state.processType, //业务类型
          selectSealType: state.selectSealType, // 盖印模式
          deptList: deptList.value
        }
        store.commit('sealManage/SET_SEALRECORDPARAMS', { ...params })
      },
      { deep: true }
    )

    //合并缓存的参数
    const mergeParams = (params) => {
      state.deptType = params.deptType
      state.printType = params.printType
      state.pagination.current = params.current
      state.pagination.pageSize = params.pageSize
      state.search = params.search
      state.searchdeptVal = params.searchdeptVal
      state.timeSort = params.timeSort
      state.submitTime = params.submitTime
      state.businessProcess = params.businessProcess
      state.processType = params.processType
      state.selectSealType = params.selectSealType
      deptList.value = params.deptList || []
    }

    return {
      state,
      searchList,
      printDeptOptions,
      reset,
      exportTable,
      handleTableChange,
      sealStatusObj,
      processTypeObj,
      examineStatusObj,
      tableRef,
      disabledDate,
      searchType,
      companyColumns,
      sealStatus,
      formRef,
      pageLoading,
      handleChange,
      deptList,
      applyFilter,
      sealUserFilter,
      sealUserLength
    }
  }
})
</script>
<style lang="scss" scoped>
.base-apply-list {
  min-height: calc(100vh - 180px);
}
.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dottos {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.text-box {
  max-width: 13em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-center {
  margin-bottom: 12px;
}
:deep(.dept-select) {
  margin-left: 1px;
  border-left: 1px solid transparent;
  line-height: 30px !important;
  &:hover {
    border-left: 1px solid #0A7BFF;
  }
}
// :deep(.ant-select-selector) {
//   height: auto !important;
//   min-height: 32px !important;
//   line-height: normal !important;
// }
// :deep(.ant-select-selection-item) {
//   line-height: 22px !important;
// }
</style>
